<template>
  <HeadlessCombobox v-model="filter.appIds" multiple>
    <AuctionButtonsContainer>
      <AuctionListFilterButton as="button" @click="toggleAll">
        {{ $t('auction-list.all') }}
      </AuctionListFilterButton>
      <AuctionListFilterButton
        v-for="app in filterData.apps"
        :key="app.id"
        :selected="filter.appIds.includes(app.id)"
        :value="app.id"
      >
        {{ app.name }}
      </AuctionListFilterButton>
    </AuctionButtonsContainer>
  </HeadlessCombobox>
</template>

<script setup lang="ts">
import type { Ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuctionStore } from '@autobid/nuxt-pinia-store/store/useAuctionStore'
import type { ParsedFilterData } from '@autobid/strapi-integration/typescript/FilterData'
import AuctionListFilterButton from './AuctionListFilterButton.vue'
import AuctionButtonsContainer from './AuctionButtonsContainer.vue'

const auctionStoreKey = inject('auctionStoreKey')
const { filter } = storeToRefs(useAuctionStore(auctionStoreKey))

const filterData = inject('filterData') as Ref<ParsedFilterData>

const toggleAll = () => {
  filter.value.appIds =
    filter.value.appIds.length === filterData.value.apps.length
      ? []
      : filterData.value.apps.map(({ id }) => id)
}
</script>
